<template>
	<div class="c-search-loader">
		<SvgLinkCircle class="transform -rotate-90 w-full" />
	</div>
</template>

<script>
import SvgLinkCircle from '~/assets/svgs/link-circle-icon.svg?inline';

export default {
	name: 'SearchLoader',
	components: { SvgLinkCircle },
};
</script>

<style>
.c-search-loader svg circle {
	stroke-dasharray: 75;
	animation: loader 1600ms infinite cubic-bezier(0.5, 0.035, 0.19, 1);
}

@keyframes loader {
	0% {
		stroke-dashoffset: 75;
	}

	100% {
		stroke-dashoffset: 225;
	}
}
</style>
